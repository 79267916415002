import { host, isApiConsole } from "../App";
import { CalendarAction } from "../slice/calendar-slice";
import { store } from "../store";
import { LogDecorator } from "../utilities/log-decorator";
import { object2QueryParamStr } from "../utilities/object-2-query-param";
import { toCalendarEventApiRequest, toCalendarEventApiResponse, toCalendarEventResponse } from "./replace";
import { CalendarEventRequest,  Event } from "./request-model";
import { CalendarEventApiResponse, CalendarEventResponse } from "./response-model";
import ResponseLocalJson from "./response.json";
// import {} from "cheerio";

const responseOk = {  // ダミー
  status: 200,
  data: "success"
}

type Param = {
  debug?: boolean,
  isLoadLocalJson?: boolean,
}
export class ApiAdmin {
  private debug = false;
  private isLoadLocalJson = false;

  constructor(param: Param) {
    const { debug, isLoadLocalJson } = param;
    this.debug = Boolean(debug);
    this.isLoadLocalJson = Boolean(isLoadLocalJson);

  }

  get url() {
    return window.location.href;
  }

  getTourScheduleList(queryParam: CalendarEventRequest, option?: {isShowLoading?: boolean}): Promise<CalendarEventResponse> {
    return new Promise((resolve, reject) => {
      const replaceParam = toCalendarEventApiRequest(queryParam, 'admin');
      const queryParamStr = object2QueryParamStr(replaceParam);
      const query = `?action_admin_tour_schedule=1&${queryParamStr}`;

      if (this.isLoadLocalJson) {
        const response = ResponseLocalJson as any as CalendarEventResponse
        if (isApiConsole) {
          window.console.groupCollapsed(...LogDecorator(`[<magenta>JSON</magenta>] - ${query}`));
          window.console.log('Request', replaceParam);
          window.console.log('Response', response);
          window.console.groupEnd();
        }
        resolve(response);
      } else {
        const url = `${host}/admin/${query}`;
        window.fetch(url)
        .then((res) => res.text())
        .then((text) => {
          const doc = new DOMParser().parseFromString(text, 'text/html');
          const element = doc.getElementById('calendar-data');
          const innerText = element?.innerText || '';
          try {
            const response = JSON.parse(innerText) as CalendarEventApiResponse;
            if (isApiConsole) {
              window.console.groupCollapsed(...LogDecorator(`[<green>200</green>] - ${query}`));
              window.console.log('Request', replaceParam);
              window.console.log('Response', response);
              window.console.groupEnd();
            }
            const replaceRes = toCalendarEventResponse(response, 'admin');
            store.dispatch(CalendarAction.setIsLoading(false));
            resolve(replaceRes);
          } catch (err) {
            if (isApiConsole) {
              window.console.error(err);
              window.console.warn('仮データをセットしました。')
              window.console.groupCollapsed(...LogDecorator(`[<red>error</red>] - ${query}`));
              window.console.log('Request', replaceParam);
              window.console.log('Response', 'error');
              window.console.groupEnd();
            }
          }
        }); 
      }
    })
  }

  changePageTourEdit(queryParam: {
    mode: 'update' | 'insert',
    id: string | number,
  }) {
    const queryParamStr = object2QueryParamStr(queryParam);
    if (!this.debug) {
      window.location.search = `?action_admin_tour_schedule_edit_init=1&${queryParamStr}`;
    }
    // window.console.log('Request', queryParam)
    // window.console.log('QueryParam', queryParamStr);
    // window.console.groupEnd();
  }

  changePageTourTourRegister(queryParam: {
    mode: 'update' | 'insert',
    preferred_date?: string,
  }) {
    const queryParamStr = object2QueryParamStr(queryParam);
    if (!this.debug) {
      window.location.search = `?action_admin_tour_schedule_edit_init=1&${queryParamStr}`;
    }
    // window.console.group(' ---- api.goToEditPage')
    // window.console.groupEnd();
  }
}
