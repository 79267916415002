import { CalendarManager } from '../../features/calendar/calendar.manager';
import { useCallback, useEffect, useState } from 'react';
import { DateViewModel } from '../../features/calendar/calendar.model';
import { TourKindTab } from '../client/tour-kind-tab';
import { apiAdmin, apiClient, } from '../../App';
import { Data } from '../../api/response-model';
import { TourPlaceRadio } from '../client/tour-place-radio';
import { OneDayPulldown } from '../client/one-day-pulldown';
import { TourPlacePulldown } from '../admin/tour-place-pulldown';
import { TourKindCheckbox } from '../admin/tour-kind-checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { CalendarAction } from '../../slice/calendar-slice';
import { date2Number, getIsToday } from '../../utilities/check-date-format';
import { TelButton } from '../client/tel-button';
import { HolidayIcon } from '../admin/holiday-icon';
import { getDayEventUtility, getMonthEventListUtility } from '../../utilities/calendar-utillitis';
import lodash from 'lodash';
import ReactLoading from 'react-loading';
import { Loading } from '../loading/loading';
import { apiDelay } from '../../api/api-delay-response';
import { topZeroDelete } from '../../utilities/time-formatter';

const cm = new CalendarManager({ debug: false });
/** 前日の13時までのWeb予約を可能とする仕様にするかどうか */
const isReservationPreDay13h: boolean = true;

type Param = {};

export const Calendar = (param: Param) => {
  const { response, tourEventAllList, selectedSearchOptState, isLoading } = useSelector((state: RootState) => ({
    response: state.calendar.response,
    tourEventAllList: state.calendar.tourEventAllList,
    selectedSearchOptState: state.calendar.selectedSearchOptionState,
    isLoading: state.calendar.isLoading,
  }));
  const dispatch = useDispatch();
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [data, setData] = useState<DateViewModel[][]>([]);
  const [monthEventList, setMonthEventList] = useState<Data[][]>([[]]);
  const [holidayList, setHolidayList] = useState<boolean[]>([]);
  const [today, setToday] = useState(new Date());
  // const [today, setToday] = useState(new Date(2022, 4, 19, 14)); // テスト用
  const [eventAll, setEventAll] = useState<Data[][]>([[]]);


  const getDayEvent = useCallback((year: string | number, month: string | number, day: string | number) => {
    return getDayEventUtility(response, year, month, day);
  }, [response.data, getDayEventUtility]);

  const getIsHoliday = useCallback((date: Date) => {
    const d = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
    };
    const { holidays } = response;
    const str = `${String(d.y).padStart(4, '0')}-${String(d.m).padStart(2, '0')}-${String(d.d).padStart(2, '0')}`;
    return holidays.includes(str);
  }, [response]);

  /** その日にイベントがなにかしら開催されているか */
  const getIsEventAll = useCallback((date: Date) => {
    let flag = false;
    tourEventAllList.forEach((v1, i1) => {
      if (i1 + 1 === date.getDate()) {
        if (v1.length) {
          flag = true;
        }
      }
    })
    return flag;
  }, [tourEventAllList]);

  const handlerSetMonthEventList = useCallback(() => {
    const list = getMonthEventListUtility(response, year, month);
    setMonthEventList(list);
  }, [year, month, getDayEvent, getIsHoliday, response]);

  const onChangeMonth = useCallback(async (nextMonth: number) => {
    let m = nextMonth;
    let y = year;

    if (nextMonth < 1) {
      m = 12;
      y = year - 1;
    } else if (nextMonth > 12) {
      m = 1;
      y = year + 1;
    }

    setMonth(m);
    setYear(y);
    if (response.site_kind === 'client') {
      const res = await apiClient.getTourScheduleList({ ...selectedSearchOptState, event_month: m, event_year: y });
      dispatch(CalendarAction.setResponse(res));
      handlerSetMonthEventList();
    } else {
      await apiDelay([
        (async () => {
          const res = await apiAdmin.getTourScheduleList({ ...selectedSearchOptState, event_month: m, event_year: y });
          const res2 = await apiAdmin.getTourScheduleList({
            ...selectedSearchOptState,
            tour_kinds: res.tour_kind.map(v => String(v.id)),
            event_year: y,
            event_month: m,
          })
          const list = getMonthEventListUtility(res2, y, m);
          dispatch(CalendarAction.setResponse(res));
          handlerSetMonthEventList();
        })(),
      ]);
    }
  }, [year, response, handlerSetMonthEventList, selectedSearchOptState]);


  const getHolidayEvent = useCallback((year: string | number, month: string | number, day: string | number) => {
    const list = response.holidays.map(v => new Date(v));
    return list.filter(v =>
      Number(v.getFullYear()) === Number(year) &&
      Number(v.getMonth()) === Number(month) &&
      Number(v.getDate()) === Number(day)
    );
  }, [response]);

  /**
   * 電話アイコンを表示するか（今日の日付の次の日の13時までは電話アイコンを表示する）
   */
  const getIsShowTel = useCallback((date: Date) => {
    const now = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      date: today.getDate(),
      hours: today.getHours(),
      minutes: today.getMinutes(),
    };
    const target = {
      year: date.getFullYear(),
      month: date.getMonth(),
      date: date.getDate(),
    };
    const limitDate = new Date(
      target.year,
      target.month,
      target.date - 1,
      13,
      0,
      0,
    );
    const limit = {
      year: limitDate.getFullYear(),
      month: limitDate.getMonth() + 1,
      date: limitDate.getDate(),
      hours: limitDate.getHours(),
      minutes: limitDate.getMinutes(),
    };

    // console.log( date.getDate() ,{
      // now: {
      //   y: now.year,
      //   m: now.month,
      //   d: now.date,
      //   h: now.hours,
      //   mi: now.minutes,
      // },
      // limit: {
      //   y: limit.year,
      //   m: limit.month,
      //   d: limit.date,
      //   h: limit.hours,
      //   mi: limit.minutes,
      // },
      // todayNumber: {
      //   n: date2Number(today, {isNaturalMonth: true})
      // },
      // limitNumber: {
      //   n: date2Number(limitDate, { isNaturalMonth: true }),
      // },
      // telFlag: (
      //   now.year === limit.year
      //   && now.month === limit.month
      //   && now.date === limit.date
      //   // && date2Number(today, { isNaturalMonth: true }) <= date2Number(limitDate, { isNaturalMonth: true })
      // )
    // })
    return (
      now.year === limit.year
      && now.month === limit.month
      && now.date === limit.date
      && (isReservationPreDay13h && (date2Number(today, { isNaturalMonth: true }) >= date2Number(limitDate, { isNaturalMonth: true }))) // 前日の13時まではWeb予約可能バージョン
      // && (isReservationPreDay13h && (date2Number(today, { isNaturalMonth: true }) >= date2Number(limitDate, { isNaturalMonth: true }))) // 前日の13時までは電話予約可能バージョン
    )
  }, [today]);

  // const getDisplayTypeClientSide = useCallback((date: Data): 'none' | 'tour' | 'tel' => {

  // }, []);

  /** クライアント側｜今日以前のイベントは表示しない。 */
  const getIsShowEventClientSide = useCallback((date: Date) => {
    const now = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      date: today.getDate(),
    };
    const target = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      date: date.getDate(),
    };

    const nowDate = new Date(now.year, now.month, now.date);
    const targetDate = new Date(target.year, target.month, target.date);
    // targetDateの１日前の日付を取得
    const yesterday = new Date(target.year, target.month, target.date - 1);
    // targetDateの２日前の日付を取得
    const pre2Day = new Date(target.year, target.month, target.date - 2);
    // 現日付はtargetDateの２日前以降かどうか
    const isPre2Days = Number(nowDate) <= Number(pre2Day);
    // 今日以前のイベントは表示しない（TRUE=今日よりあと、FALSE=今日以前）
    const isAfterToday = Number(nowDate) < Number(targetDate);
    // 13時以降だった場合はイベントは表示しない（TRUE=今日13時以降、FALSE=今日13時以前）
    const isAfter13h = today.getHours() >= 13;
    // 今日の日付はtargetdateの１日前かどうか
    const isYesterday =
      yesterday.getFullYear() === nowDate.getFullYear() &&
      yesterday.getMonth() === nowDate.getMonth() &&
      yesterday.getDate() === nowDate.getDate();
    
    // console.log({
    //   target: `${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}`,
    //   isAfterToday: isAfterToday,
    //   isAfter13h: isAfter13h,
    //   isPre2Days: isPre2Days,
    //   isYesterday: isYesterday,
    //   isReservationPreDay13h: isReservationPreDay13h,
    //   result: isReservationPreDay13h ? (isPre2Days || (isAfterToday && !isAfter13h) || (isYesterday && !isAfter13h)) : isPre2Days
    // })
    if (new Date(target.year, target.month, 0).getDate() === target.date ) {
      // console.log(`--- today: ${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()} ${today.getHours()}:${today.getMinutes()}`)
    }
    
    return isReservationPreDay13h ? (isPre2Days || (isAfterToday && !isAfter13h) || (isYesterday && !isAfter13h)) : isPre2Days;

  }, [today, getIsShowTel]);


  const handlerClickDateBox = useCallback((date: DateViewModel) => {
    const d = date.dateObj;
    if (response.site_kind === 'admin') {
      apiAdmin.changePageTourTourRegister({
        mode: 'insert',
        preferred_date: `${d.getFullYear()}/${String(d.getMonth() + 1).padStart(2, '0')}/${String(d.getDate()).padStart(2, '0')}`
      })
    } else {
    }
  }, [response]);

  const handlerClickEvent = useCallback((data: Data) => {
    if (response.site_kind === 'admin') {
      apiAdmin.changePageTourEdit({
        mode: 'update',
        id: String(data.id),
      })
    } else {
      apiClient.changePageReserveEdit(data);
    }
  }, [response]);

  const getMonthEventListWithSerialNum = useCallback((monthEventList: Data[]) => {
    if (response.site_kind === 'admin') {
      const idList = response.tour_kind.map(v => String(v.id));

      return monthEventList.map((v, i) => {
        const index = idList.indexOf(String(v.tour_kind));
        if (index != -1) {
          return {
            data: v,
            serialNum: index,
          }
        } else {
          return {
            data: v,
            serialNum: 0,
          }
        }
      })
    } else {
      return monthEventList.map((v, i) => ({ data: v, serialNum: i }))  
    }
  }, [response]);

  // - effect -
  // -- year --
  useEffect(() => cm.setYear(year), [year]);
  // -- month --
  useEffect(() => cm.setMonth(month), [month]);
  // -- month --
  useEffect(() => {
    setData(cm.data);
    // - add event -
    cm.on('change:data', (p) => {
      setData(p.data);
    });
    // - calendar initialize -
    cm.initialize(year, month);

    setToday(new Date());
    // setToday(new Date(2022, 4, 3, 12, 59)); // テスト用


  }, []);

  useEffect(() => {
    handlerSetMonthEventList();
  }, [response, handlerSetMonthEventList]);

  useEffect(() => {
    const { event_year, event_month} = response.request;
    setYear(Number(event_year));
    setMonth(Number(event_month));

  }, [response.request]);


  return (
    <div id="calendar" className={response.site_kind}>
      {response.site_kind === 'admin' ? (
        <>
          <TourPlacePulldown />
          <TourKindCheckbox />
        </>
      ) : (
        <>
          <TourPlaceRadio />
          <TourKindTab />
          <OneDayPulldown />
        </>
      )}

      <div className='select_month'>
        <button
          className='prev'
          onClick={() => onChangeMonth(month - 1)}
        >
        </button>
        {/*{`${month - 1 < 1 ? 12 : month - 1}月`}*/}
        <div className='current_month'>
          {`${year}`}年&nbsp;{`${month}`}月&nbsp;スケジュール
        </div>
        {/*{`${month + 1 > 12 ? 1 : month + 1}月`}*/}
        <button
          className='next'
          onClick={() => onChangeMonth(month + 1)}
        >
        </button>
      </div>

      <div className='table_wrap'>
        <table>
          <thead>
            <tr>
              <th>日</th>
              <th>月</th>
              <th>火</th>
              <th>水</th>
              <th>木</th>
              <th>金</th>
              <th>土</th>
            </tr>
          </thead>
          <tbody>
            {data.map((week, i) => (
              <tr
                key={`data_${i}`}
              >
                {week.map((d, j) => (
                  <td
                    key={`week_${i}_${j}`}
                    className={
                      !d.isCurrentMonth ? 'other' : (
                        response.site_kind === 'client' && getIsToday(today, d.dateObj) ? 'today' : (
                          response.site_kind === 'admin' && getIsHoliday(d.dateObj) ? 'holiday' : ''
                        )
                      )
                    }
                    onClick={() => handlerClickDateBox(d)}
                  >
                    {d.date}
                    {/* <br />{
                      // 確認用
                      !d.isCurrentMonth ? 'other' : (
                        response.site_kind === 'client' && getIsToday(today, d.dateObj) ? 'today' : (
                          response.site_kind === 'admin' && getIsHoliday(d.dateObj) ? 'holiday' : ''
                        )
                      )
                    }
                    <br />{
                      `${getIsShowEventClientSide(d.dateObj)} && ${!(response.site_kind === 'client')} && ${getIsHoliday(d.dateObj)}`
                    }
                    <br />{
                      d.isCurrentMonth && (response.site_kind === 'admin' || getIsShowEventClientSide(d.dateObj)) && !(response.site_kind === 'client' && getIsHoliday(d.dateObj)) && monthEventList[d.date - 1].length ? '表示' : 'なし'
                    } */}
                    { response.site_kind === 'client' && getIsShowTel(d.dateObj) ? (
                      <div key={`tel_${i}`} >
                        <TelButton />
                      </div>
                    ) : (
                      <div
                        key={`date_${i}_${j}`}
                        className="button_wrap"
                      >
                        { d.isCurrentMonth &&
                          (response.site_kind === 'admin' || getIsShowEventClientSide(d.dateObj)) &&
                          !(response.site_kind === 'client' && getIsHoliday(d.dateObj)) ? (
                            monthEventList[d.date - 1].length ? (
                              getMonthEventListWithSerialNum(monthEventList[d.date - 1])?.map((v, i) => (
                                <>
                                  {/* {v.data.event_day} */}
                                  <button
                                    key={`event_${i}`}
                                    className={`tour_${v.serialNum}`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handlerClickEvent(v.data);
                                    }}
                                    disabled={response.site_kind === 'client' && v?.data.reception_end === 't'}
                                  >
                                    <span key={`span1_${i}`} className='top time'>
                                      {`${topZeroDelete(v.data.start_time.slice(0, -3))}`}
                                    </span>
                                    <span className='bottom' key={`span2_${i}`}>
                                      <span className='price' key={`span3_${i}`}>
                                        {`¥ ${Number(v.data.price_adult).toLocaleString()}`}
                                      </span>
                                      { response.site_kind === 'admin' ? 
                                        <span className='flag' key={`span4_${i}`}>
                                          {`${v.data.reception_end === 'f' ? '○' : '×'}`}
                                        </span>
                                      : <></>}
                                    </span>
                                    { response.site_kind === 'admin' ? 
                                      <span className=
                                      {v.data.repeater_discount_flag === '1' ? "discount on" : "discount off"}
                                       ></span>
                                     : <></>}
                                  </button>
                                </>
                              ))
                            ) : (
                              // response.site_kind === 'admin' && getIsHoliday(d.dateObj) ? <HolidayIcon /> : <></>
                              <></>
                            )
                          ) : (
                        <>
                          {/* {'none'} */}
                        </>)}
                      </div>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        { response.site_kind === 'admin' && isLoading ? <Loading /> : <></> }

      </div>
    </div>
  );
};