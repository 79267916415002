import { CalendarEventResponse } from "../api/response-model";

export const getDayEventUtility = (
  response: CalendarEventResponse,
  year: string | number, month: string | number, day: string | number
) => {
  return response.data.filter(v =>
    Number(v.event_year) === Number(year) &&
    Number(v.event_month) === Number(month) &&
    Number(v.event_day) === Number(day)
  );
}

export const getMonthEventListUtility = (
  response: CalendarEventResponse,
  year: number, month: number,
) => {
  const lastDay = new Date(year, month, 0).getDate();
  const list = [...new Array(lastDay)].map((v, i) => getDayEventUtility(response, year, month, i + 1) ?? []);
  return list;
}