import { CalendarManager } from '../../features/calendar/calendar.manager';
import { useCallback, useEffect, useState } from 'react';
import { DateViewModel } from '../../features/calendar/calendar.model';
import { apiAdmin } from '../../App';
import { TourKind, TourPlaces } from '../../api/response-model';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { CalendarAction } from '../../slice/calendar-slice';
import { getMonthEventListUtility } from '../../utilities/calendar-utillitis';
import { apiDelay } from '../../api/api-delay-response';


/**
 * 絞り込みエリア（管理側）
 * @param param 
 * @returns 
 */
export const TourPlacePulldown = () => {
  const { response } = useSelector((state: RootState) => ({
    response: state.calendar.response,
    request: state.calendar.response.request,
  }));
  const dispatch = useDispatch();
  const { tour_places, request } = response;
  const defaultValue = String(request.tour_place ?? tour_places[0].id);

  const handlerChangePulldown = useCallback(async (value: string) => {
    const today = {
      y: new Date().getFullYear(),
      m: new Date().getMonth() + 1,
    }

    await apiDelay([
      (async () => {
        const res = await apiAdmin.getTourScheduleList({
          ...response.request,
          tour_place: String(value)
        })
        const res2 = await apiAdmin.getTourScheduleList({
          ...response.request,
          tour_place: String(value),
          tour_kinds: res.tour_kind.map(v => String(v.id)),
        })
        const list = getMonthEventListUtility(res2, today.y, today.m);
        dispatch(CalendarAction.setTourEventAllList(list));
        dispatch(CalendarAction.setResponse(res2)) 
        dispatch(CalendarAction.setSelectedSearchOptionState({
          tour_place: String(value),
          tour_kinds: res.tour_kind.map(v => String(v.id)),
        }))    
      })(),
    ]);
  }, [response, ]);




  return (
    <div>
      <div className='tour_place'>
        <label className="for_select">
          <select
            defaultValue={defaultValue}
            onChange={(e) => {handlerChangePulldown(e.target.value)}}
          >
            { tour_places.map((v, i) => (
              <option
                key={`pulldown_${i}`}
                value={String(v.id)}
              >
                {v.title}
              </option>
            ))}
          </select>
          </label>
      </div>
    </div>
  );
};