import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CalendarEventRequest, Event } from "../api/request-model";
import { CalendarEventResponse, Data, TourKind } from "../api/response-model";
import lodash from 'lodash';

export const calendarEventInit: CalendarEventResponse = {
  request: {
    tour_place: '1',
    tour_kinds: ['1'],
    event_year: '2022',
    event_month: '3',  
  },
  holidays: [],
  site_kind: 'client',
  tour_places: [],
  tour_kind: [],
  data: [
  ],
}

export const selectedEventStateInit: Event = {
  event_year: '',
  event_month: '',
  event_day: '',
  start_time: '',
  end_time: '',
  regular_holiday: false,
  price_adult: '',
  price_child: '',
  tour_place: '',
  tour_kind: '',
}
export type SelectedSearchOptionState = {
  tour_place: string,
  tour_kinds: string[],
}

export const selectedSearchOptionStateInit: SelectedSearchOptionState = {
  tour_place: '',
  tour_kinds: [],
}

type PageType = 'initialLoading' | 'calendar' ;

export const pageStateInit: PageType = 'initialLoading';

export const CalendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    response: calendarEventInit,
    selectedEventState: selectedEventStateInit,
    selectedSearchOptionState: selectedSearchOptionStateInit,
    oneDayTourKindList: [] as TourKind[],
    pageState: pageStateInit as PageType,
    inputContent: selectedEventStateInit,
    tourEventAllList: [[]] as Data[][],
    isLoading: false,
    timeOut: null as (null | number)
  },
  reducers: {
    setResponse: (state, action: PayloadAction<typeof state.response>) => {
      state.response = action.payload;
    },
    setSideKind: (state, action: PayloadAction<typeof state.response.site_kind>) => {
      state.response.site_kind = action.payload;
    },
    setSelectedEventState: (state, action: PayloadAction<typeof state.selectedEventState>) => {
      state.selectedEventState = action.payload;
    },
    setSelectedSearchOptionState: (state, action: PayloadAction<typeof state.selectedSearchOptionState>) => {
      state.selectedSearchOptionState = action.payload;
    },
    setTourEventAllList: (state, action: PayloadAction<typeof state.tourEventAllList>) => {
      state.tourEventAllList = action.payload;
    },

    // setTourPlace: (state, action: PayloadAction<typeof state.selectedState.tour_place>) => {
    //   state.selectedState.tour_place = action.payload;
    // },
    // setTourKinds: (state, action: PayloadAction<typeof state.selectedState.tour_kinds>) => {
    //   state.selectedState.tour_kinds = action.payload;
    // },
    // setEventYear: (state, action: PayloadAction<typeof state.selectedState.event_year>) => {
    //   state.selectedState.event_year = action.payload;
    // },
    // setEventMonth: (state, action: PayloadAction<typeof state.selectedState.event_month>) => {
    //   state.selectedState.event_month = action.payload;
    // },
    setOneDayTourKindList: (state, action: PayloadAction<typeof state.oneDayTourKindList>) => {
      state.oneDayTourKindList = action.payload;
    },
    setPageState: (state, action: PayloadAction<PageType>) => {
      state.pageState = action.payload;
    },
    setInputContent: (state, action: PayloadAction<typeof state.inputContent>) => {
      state.inputContent = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<typeof state.isLoading>) => {
      state.isLoading = action.payload;
      // if (action.payload) {
      //   state.timeOut ? window.clearTimeout(state.timeOut) : (() => {})();
      //   state.isLoading = true;

      //   state.timeOut = window.setTimeout(() => {
      //     state.timeOut ? window.clearTimeout(state.timeOut) : (() => {})();
      //     state.timeOut = null;
      //     state.isLoading = false;
      //   }, 2000);
      // } else {
      //   if (!state.timeOut) {
      //     state.timeOut ? window.clearTimeout(state.timeOut) : (() => {})();
      //     state.timeOut = null;
      //     state.isLoading = false;
      //   }
      // }
    },
  }
})

export const CalendarAction = CalendarSlice.actions;
export const CalendarReducer = CalendarSlice.reducer;