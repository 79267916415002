import ReactLoading from 'react-loading';

export const Loading = () => {
  return (
    <div>
      <div className='calendar_loading'>
        <ReactLoading
          color='#c0c0c0'
          type='spin'
          height='5%'
          width='5%'
        />
      </div>
    </div>
  )
}