import { CalendarManager } from '../../features/calendar/calendar.manager';
import { useCallback, useEffect, useState } from 'react';
import { DateViewModel } from '../../features/calendar/calendar.model';
import { apiAdmin } from '../../App';
import { TourKind, TourPlaces } from '../../api/response-model';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { CalendarAction } from '../../slice/calendar-slice';
import { apiDelay } from '../../api/api-delay-response';


/**
 * ツアー選択チェックボックス（管理側）
 * @param param 
 * @returns 
 */
export const TourKindCheckbox = () => {
  const { tour_kind, request, response, selectedSearchOptState } = useSelector((state: RootState) => ({
    tour_kind: state.calendar.response.tour_kind,
    request: state.calendar.response.request,
    response: state.calendar.response,
    selectedSearchOptState: state.calendar.selectedSearchOptionState,
  }));
  const dispatch = useDispatch();
  const [isAll, setIsAll] = useState(false);

  const onChangeCheckBox = useCallback(async (obj: TourKind | 'all' ) => {
    const selectedState = selectedSearchOptState;
    await apiDelay([
      (async () => {
        if (obj === 'all') {
          if (selectedState.tour_kinds.length === response.tour_kind.length) {
            apiAdmin.getTourScheduleList({...request, tour_kinds: []}).then((res) => {
              dispatch(CalendarAction.setResponse(res)
            )})
            dispatch(CalendarAction.setSelectedSearchOptionState({...selectedState, tour_kinds: []}));  
          } else {
            const listIdAll = response.tour_kind.map(v => String(v.id));
            apiAdmin.getTourScheduleList({...request, tour_kinds: [...listIdAll]}).then((res) => {
              dispatch(CalendarAction.setResponse(res))
            })
            dispatch(CalendarAction.setSelectedSearchOptionState({...selectedState, tour_kinds: [...listIdAll]}));  
          }
        } else {
          if (selectedState.tour_kinds.includes(String(obj.id))) {
            const arr = selectedState.tour_kinds.filter(v => String(v) !== String(obj.id) );
            apiAdmin.getTourScheduleList({...request, tour_kinds: [...arr]}).then((res) => {
              dispatch(CalendarAction.setResponse(res))
            })
            dispatch(CalendarAction.setSelectedSearchOptionState({...selectedState, tour_kinds: [...arr]}));
          } else {
            const arr = [...selectedState.tour_kinds, String(obj.id)];
            apiAdmin.getTourScheduleList({...request, tour_kinds: [...arr]}).then((res) => {
              dispatch(CalendarAction.setResponse(res))
            })
            dispatch(CalendarAction.setSelectedSearchOptionState({...selectedState, tour_kinds: [...arr]}));
          }
        }
      })(),
    ]);

  }, [isAll, selectedSearchOptState, request]);

  useEffect(() => {

  }, [response])

  return (
    <div>
      <div className="tour_kind">

        {/** 全選択チェックボックス ******** */}
        <div className="checkbox">
          <label
            key={`label_all`}
            className={selectedSearchOptState.tour_kinds.length === response.tour_kind.length ? 'checked' : ''}
          >
            <input
              key={`checkbox_all`}
              type={'checkbox'}
              checked={selectedSearchOptState.tour_kinds.length === response.tour_kind.length}
              onChange={() => onChangeCheckBox('all')}
            />
            全選択
          </label>
        </div>

        <div>
          {/** 通常のツアー ******** */}
          <div>
            { tour_kind.map((v, i) => (
              !v.one_day ? (
                <div className="checkbox">
                  <label
                    key={`label_${i}`}
                    className={`tour_kind_${i} ${selectedSearchOptState.tour_kinds.includes(String(v.id)) ? 'checked' : ''}`}
                  >
                    <input
                      key={`checkbox_${i}`}
                      type={'checkbox'}
                      checked={selectedSearchOptState.tour_kinds.includes(String(v.id))}
                      onChange={() => onChangeCheckBox(v)}
                    />{v.title}
                  </label>
                </div>  
              ) : (<></>)
            ))}
          </div>

          {/** コンボのツアーのみ ******** */}
          <div>
          { tour_kind.map((v, i) => (
            v.one_day ? (
              <div className="checkbox">
                <label
                  key={`label_${i}`}
                  className={`tour_kind_${i} ${selectedSearchOptState.tour_kinds.includes(String(v.id)) ? 'checked' : ''}`}
                >
                  <input
                    key={`checkbox_${i}`}
                    type={'checkbox'}
                    checked={selectedSearchOptState.tour_kinds.includes(String(v.id))}
                    onChange={() => onChangeCheckBox(v)}
                  />{v.title}
                </label>
              </div>
            ) : (<></>)
          ))}
          </div>
        </div>
      </div>
    </div>
  );
};