import { store } from "../store";
import { CalendarEventApiRequest, CalendarEventRequest } from "./request-model";
import { CalendarEventApiResponse, CalendarEventResponse } from "./response-model";

export const toArrayTourPlaceId = (id: number | string) => {
  return ['1', '3'].includes(String(id)) ? ['1', '3'] : [String(id)];
}

export const toTourPlaceId = (id: number[] | string[]) => {
  const str = id.map(v => String(v));
  return str.includes('1') || str.includes('3') ? '1' : '2';
}

export const toCalendarEventApiRequest = (
    queryParam: CalendarEventRequest,
    siteKind: 'client' | 'admin',
): CalendarEventApiRequest => {
  if (siteKind === 'client') {
    return {
      tour_places: toArrayTourPlaceId(queryParam.tour_place ?　String(queryParam.tour_place) : '1' ),
      tour_kinds: queryParam.tour_kinds,
      event_year: queryParam.event_year,
      event_month: queryParam.event_month,
    }
  } else {
    return {
      tour_places: [String(queryParam.tour_place)],
      tour_kinds: queryParam.tour_kinds,
      event_year: queryParam.event_year,
      event_month: queryParam.event_month,
    }
  }
}
export const toCalendarEventRequest = (
  queryParam: CalendarEventApiRequest,
  siteKind: 'client' | 'admin',
): CalendarEventRequest => {
  if (siteKind === 'client') {
    return {
      tour_place: queryParam.tour_places ? toTourPlaceId(queryParam.tour_places) : '1',
      tour_kinds: queryParam.tour_kinds,
      event_year: queryParam.event_year,
      event_month: queryParam.event_month,
    }  
  } else {
    return {
      tour_place: queryParam.tour_places ? String(queryParam.tour_places[0]) : '1',
      tour_kinds: queryParam.tour_kinds,
      event_year: queryParam.event_year,
      event_month: queryParam.event_month,
    }  
  }
}


export const toCalendarEventApiResponse = (
  response: CalendarEventApiResponse,
  siteKind: 'client' | 'admin',
  ): CalendarEventApiResponse => {
  return {
    ...response,
    request: toCalendarEventApiRequest(response.request, siteKind),
  }
}

export const toCalendarEventResponse = (
  response: CalendarEventApiResponse,
  siteKind: 'client' | 'admin',
  ): CalendarEventResponse => {
  return {
    ...response,
    request: toCalendarEventRequest(response.request, siteKind),
  }
}