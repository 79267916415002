import { URLSearchParams } from "node:url";

/**
 * クエリパラメタをオブジェクトに変換する
 * @param object 
 * @returns 
 */
export const queryParamStr2Object = <T>(locationSearchStr: string): T => {
  const queries = locationSearchStr ? locationSearchStr.substr(1).split('&') : []
  const params = queries.reduce((acc: any, q) => {
    var key = q.split('=')[0]
    var value = q.split('=')[1]
    acc[key] = decodeURIComponent(value)
    return acc
  }, {})
  return params;
}
