import { CalendarManager } from '../../features/calendar/calendar.manager';
import { useCallback, useEffect, useState } from 'react';
import { DateViewModel } from '../../features/calendar/calendar.model';
import { apiClient } from '../../App';
import { CalendarEventResponse, TourKind, TourPlaces } from '../../api/response-model';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { CalendarAction } from '../../slice/calendar-slice';



/**
 * コンボタブ選択時のプルダウン
 * @param param 
 * @returns 
 */
export const OneDayPulldown = () => {
  const { response, oneDayTourKindList, selectedSearchOptionState } = useSelector((state: RootState) => ({
    response: state.calendar.response,
    oneDayTourKindList: state.calendar.oneDayTourKindList,
    selectedSearchOptionState: state.calendar.selectedSearchOptionState,
  }));
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);


  const handlerChangePulldown = useCallback((value: string) => {
    const tourKind = oneDayTourKindList.find(v => String(v.id) === value);
    const ssos = selectedSearchOptionState;
    if (tourKind) {
      apiClient.getTourScheduleList({...response.request, tour_kinds: [tourKind.id]});
      dispatch(CalendarAction.setSelectedSearchOptionState({...ssos, tour_kinds: [tourKind.id]}))
    }
  }, [oneDayTourKindList, response, selectedSearchOptionState]);

  useEffect(() => {
    const selectedTourKindId = String(selectedSearchOptionState.tour_kinds[0]);
    const oneDayTourKindIdList = oneDayTourKindList.map(v => String(v.id));
    setIsShow(oneDayTourKindIdList.includes(String(selectedTourKindId)));
  }, [oneDayTourKindList, selectedSearchOptionState]);

  return (
    <div>
      { isShow ? (
        <label className="for_select for_oneday">
          <select
            defaultValue={oneDayTourKindList[0] ? oneDayTourKindList[0].id : '0'}
            onChange={(e) => {handlerChangePulldown(e.target.value)}}
          >
            { oneDayTourKindList.map((v, i) => (
              <option
                key={`pulldown_${i}`}
                value={v.id}
              >
                {v.title}
              </option>
            ))}
          </select>
        </label>
      ) : (<></>)
      }
    </div>
  );
};