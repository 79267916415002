import { CalendarEventModel } from './calendar-event.model';
import {
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfMonth,
  endOfWeek, getDate,
  getDay,
  getMonth,
  startOfMonth,
} from 'date-fns/fp';
import EventEmitter from 'eventemitter3';
import { DateViewModel } from './calendar.model';

/**
 * イベント
 */
type EventType = {
  'change:data': (p: { data: DateViewModel[][] }) => void,
};

type Param = {
  year?: number,
  month?: number,
  debug?: boolean,
};

/**
 * カレンダーモデル
 * イベントも内包する
 */
export class CalendarManager extends EventEmitter<EventType> {

  data: DateViewModel[][];
  events: CalendarEventModel[];

  private year: number;
  private month: number;
  private debug = false;

  constructor(param: Param) {
    super();
    const { year, month, debug } = param;
    this.year = year ?? new Date().getFullYear();
    this.month = month ?? new Date().getMonth() + 1;
    this.data = [];
    this.events = [];
    this.debug = Boolean(debug);
  }

  /**
   * 初期化を行う
   * @param year
   * @param month
   */
  initialize(year: number, month: number) {
    this.year = year;
    this.month = month;
    this.createDays(this.year, this.month);
  }

  /**
   * 年/月 を変更
   * @param year
   * @param month
   */
  setYearMonth(year: number, month: number) {
   this.year = year;
   this.setMonth(month);
  }

  /**
   * 年 を変更
   * @param nextYear
   */
  setYear(nextYear: number) {
    this.year = nextYear;
    this.createDays(this.year, this.month);
  }

  /**
   * 月 を変更
   * @param nextMonth
   */
  setMonth(nextMonth: number) {
    if (!(nextMonth <= 12 || nextMonth >= 1)) {
      throw new RangeError(`nextMonth is out of range !! ${nextMonth}`);
    }
    this.month = nextMonth;
    this.createDays(this.year, this.month);
  }

  /**
   * カレンダー描画用の日の二次元配列を生成
   * 生成後はイベントを発火
   * @param year
   * @param month
   * @private
   */
  private createDays(year: number, month: number) {
    if (!(month <= 12 || month >= 1)) {
      throw new RangeError(`month is out of range !! ${month}`);
    }
    const date = new Date(`${year}/${String(month).padStart(2, '0')}/01`);
    const days: DateViewModel[][] = eachWeekOfInterval({
      start: startOfMonth(date),
      end: endOfMonth(date),
    })
      .map(
        (sunday) => eachDayOfInterval({
          start: sunday,
          end: endOfWeek(sunday),
        }).map((d) => (
          // DateViewModel を生成
          {
            isCurrentMonth: getMonth(d) === getMonth(date),
            dateObj: d,
            day: getDay(d),
            date: getDate(d),
          }
        ))
      )
    // - debug -
    if (this.debug) {
      console.group('createDays()');
      console.log('days : ', days);
      console.groupEnd();
    }
    this.data = days;
    // - fire event -
    this.emit('change:data', { data: this.data });
  }

}
