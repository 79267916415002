/****************************************************************
* 機　能： 入力された値が日付でYYYY/MM/DD形式になっているか調べる
* 引　数： strDate = 入力された値
* 戻り値： 正 = true　不正 = false
****************************************************************/
export const checkDateFormat = (strDate: string) => {
  if (!strDate.match(/^\d{4}\/\d{2}\/\d{2}$/)) {
      return false;
  }
  const y = +strDate.split("/")[0];
  const m = +strDate.split("/")[1] - 1;
  const d = +strDate.split("/")[2];
  const date = new Date( y, m, d );
  if (date.getFullYear() != y || date.getMonth() != m || date.getDate() != d) {
      return false;
  }
  return true;
}

export const getIsToday = (today: Date, date: Date) => {
  if (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  ) {
    return true;
  }
  return false;
};

/**
 * 日付をyyyymmddhhmmss形式の数値に変換する。
 * Monthは 0=１月, 1=2月・・・、となることに注意する。
 * @param date Dateインスタンス
 * @param option isNaturalMonth: Monthを純粋な 1=1月， 2=2月・・・、として計算するか
 * @returns 
 */
export const date2Number = (date: Date, option?: {isNaturalMonth?: boolean}) => {
  const d = {
    year: date.getFullYear(),
    month: date.getMonth() + (option ? (option?.isNaturalMonth ? 1 : 0) : 0),
    date: date.getDate(),
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds(),
  }
  return (
    Number(
      String(d.year).padStart(4, '0') + 
      String(d.month).padStart(2, '0') +
      String(d.date).padStart(2, '0') +
      String(d.hours).padStart(2, '0') +
      String(d.minutes).padStart(2, '0') +
      String(d.seconds).padStart(2, '0')
    )
  )
}